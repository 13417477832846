import React from 'react'
import styled, { css } from 'styled-components'

const OriginalWestEndCastStyles = styled.div`
    position: absolute;
    bottom: 0;
    right: 1rem;
    z-index: 9999;
    width: 100%;
    div {
        max-width: 1300px;
        margin: 0 auto;
        p {
            text-align: right;
            color: #fff;
            font-size: .9rem;
        }
    }
    ${props => props.$index && css`
        bottom: 2rem;
        right: 0;
    `}

`
const OriginalWestEndCast = props => {
  return (
    <OriginalWestEndCastStyles {...props}>
        <div>
            <p>Original West End Cast</p>
        </div>
    </OriginalWestEndCastStyles>
  )
}

export default OriginalWestEndCast