import React from 'react'
import { Link } from 'gatsby'

const NYFooterLinks = () => {
  return (
    <p className="links">
    © Colin Ingram Ltd <br className="mob" />
    Website by Dewynters <br className="mob" />{" "}
    <span className="desktop"> | </span>
    <Link to="/terms-conditions/">Terms &amp; Conditions</Link> <br className="mob" />{" "}
    <span className="desktop"> | </span>
    <Link to="/privacy-policy/">Privacy Policy</Link> | <Link to="/cookie-policy/">Cookie Policy</Link>
  </p>
  )
}

export default NYFooterLinks